"use client"
import CardError from "@/components/error-bound/error-card"
import { consoleHelper, networkFlow, statusConts } from "@/helper/utils"
import React, { useEffect } from "react"

export default function Error({ error, reset }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
    // console.log(error)
    consoleHelper(
      statusConts.error,
      error.message,
      networkFlow.received,
      "something went wrong!"
    )
  }, [error])

  return (
    <div className="pt-[58px] lg:pt-[72px] min-h-[calc(100dvh-170px)] bg-bg-1 relative">
      <CardError reset={reset} />
    </div>
  )
}
