"use client"
import React from "react"
import { Image } from "../shared/image/image"
import Link from "next/link"
import ButtonAction from "../shared/button-action/button-action"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useIsDesktop } from "@/hooks/use-is-mobile"

const CardError = ({ reset }) => {
  const { isLogin } = useIsLogin()
  const isDesktop = useIsDesktop()

  return (
    <>
      <Image
        className="absolute inset-0 z-[0]"
        alt="bg"
        src={`/assets/${process.env.NEXT_PUBLIC_NAME}/profile-edit/bg-${isDesktop ? "desktop" : "mobile"}.png`}
      />
      <div className="flex flex-col justify-center pt-[66px] lg:pt-[90px] px-[25px] lg:px-0 max-w-[460px] mx-auto relative z-[1]">
        <div>
          <Image
            className="aspect-[304/177] max-w-[304px] mx-auto"
            src={`/assets/${process.env.NEXT_PUBLIC_NAME}/404/oops.png`}
            alt="404"
          />
          <div className="text-[20px] lg:text-[24px] leading-[28px] lg:leading-[32px] font-font-family-7 font-bold mt-[20px] px-[50px] lg:p-0 capitalize text-center text-text-2">
            Oops! Terjadi Kesalahan pada sistem
          </div>
          <div className="text-[16px] leading-[24px] text-center text-text-2 mt-[10px] px-[10px] lg:px-0 font-font-family-5">
            Kami sedang berusaha menangani masalah ini. Silahkan coba beberapa
            saat lagi atau kembali ke <i>Homepage</i>.
          </div>
          <div className="lg:flex gap-[10px] max-w-[400px] lg:mt-[20px] mx-auto">
            <ButtonAction
              className="mt-[20px] lg:mt-0 max-w-[355px] lg:max-w-[195px] min-h-[50px] mx-auto"
              intent={"primary"}
              onClick={reset}
            >
              Muat Ulang Halaman
            </ButtonAction>
            <Link href={isLogin ? "/br" : "/"}>
              <ButtonAction
                className="mt-[10px] lg:mt-0 max-w-[355px] lg:max-w-[195px] min-h-[50px] mx-auto"
                intent={"secondary"}
              >
                Kembali Ke Homepage
              </ButtonAction>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default CardError
